
/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************** cgu *******************************************/
    /**********************************************************************************************/

    .cgu > h1 {
        padding-top: 7vw;
        padding-bottom: 7vw;
        margin-bottom: 5vw;
    }

    .cgu > h2 {
        margin-top: 3vw;
        margin-bottom: 3vw;
        font-size: 2.3vw;
        letter-spacing: .0275vw;
    }
    
    .cgu > p {
        font-size: 1.8vw;
        line-height: 2.8vw;
    }
    
    .cgu > li {
        font-size: 1.8vw;
    }
    
    .cgu > div {
        margin-top: 3.5vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************** cgu *******************************************/
    /**********************************************************************************************/

    .cgu > h2 {
        margin-top: 2.5vw;
        margin-bottom: 2.5vw;
        font-size: 2.2vw;
    }
    
    .cgu > p {
        font-size: 1.5vw;
        line-height: 2.2vw;
    }
    
    .cgu > li {
        font-size: 1.5vw;
    }
    
    .cgu > div {
        margin-top: 4vw;
    }
}