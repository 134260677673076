/**********************************************************************************************/
/******************************************** footer ******************************************/
/**********************************************************************************************/

.footer {
    text-align: center;
    width: 100%;
    height: auto;
    font-family: Open, sans-serif;
    color: white;
    background-color: black;
}

.footer > h1 {
    margin-top: 10vw;
    font-size: 7vw;
}

.footer__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60vw;
    height: 20vw;
    text-align: center;
    align-items: center;
}

.footer__link__icon {
	position: relative;
    color: rgb(240, 207, 109);
    font-size: 9vw;
    opacity: .5;
    cursor: pointer;
    transition: all .2s;
}

.footer__link__icon:hover {
	color: rgb(238, 214, 142);
    opacity: 1;
    transition: all .2s;
}

.footer__logo-2br {
    margin-top: 12vw;
    margin-bottom: 12vw;
    width: 20vw;
    height: auto;
    cursor: pointer;
}

.footer__logo-labels {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 33vw;
    height: auto;
}

.footer__logo-labels__decennal {
    width: auto;
    height: 18vw;
}

.footer__logo-labels__qualibat {
    margin-top: 1.5vw;
    width: auto;
    height: 14vw;
}

.footer > p {
    font-size: 4.2vw;
    line-height: 6vw;
}

.mail-link {
    color: rgb(255, 220, 113) !important; 
}

.mail-link:hover {
    text-decoration: underline;
    color: rgb(255, 220, 113);
}

.footer__copyright {
    width: 100%;
    height: auto;
    padding: .25vw 7vw .25vw 7vw;
    margin-top: 20vw;
    margin-bottom: 0;
    bottom: 0;
    font-size: 3.8vw;
    line-height: 5vw;
    background-color: rgb(32, 32, 32);
}

.footer__copyright__link {
    color: rgb(177, 148, 63) !important;
}

.footer__copyright__link:hover {
    color: rgb(177, 148, 63) !important;
    text-decoration: underline;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* footer *****************************************/
    /**********************************************************************************************/
    
    .footer > h1 {
        margin-top: 5.926vw;
        font-size: 4vw;
    }
    
    .footer__link {
        width: 35.55vw;
        height: 11.852vw;
    }
    
    .footer__link__icon {
        font-size: 5.333vw;
    }
    
    .footer__logo-2br {
        margin-top: 5.25vw;
        margin-bottom: 5.25vw;
        width: 11.852vw;
    }

    .footer__logo-labels {
        width: 19.555vw;
    }
    
    .footer__logo-labels__decennal {
        height: 10.666vw;
    }
    
    .footer__logo-labels__qualibat {
        margin-top: .888vw;
        height: 8.296vw;
    }
    
    .footer > p {
        font-size: 2.2vw;
        line-height: 3.555vw;
    }
    
    .footer__copyright {
        margin-top: 8.888vw;
        padding: .15vw 7vw .15vw 7vw;
        font-size: 2.2vw;
        line-height: 3vw;
    }  
}