/**********************************************************************************************/
/****************************************** background ****************************************/
/**********************************************************************************************/

.background {
    width: auto;
    height: auto;
    overflow: hidden;
    z-index: -1;
}

.house {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    width: 236vw;
    height: auto;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/
    
    .house {
        width: 142.222vw;
    }
}