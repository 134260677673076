/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/
    
    .containerOffice__bloc__office__text__content {
        height: 31vw;
        margin-top: 10vw;
    }

    .containerOffice__bloc__office__text__container__dote {
        width: 3.5vw;
    }

    .agences-dot {
        width: 1vw;
        height: 1vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/
    
    .containerOffice__bloc__office__text__content {
        height: 31vw;
        margin-top: 12vw;
    }
}