/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* error ******************************************/
    /**********************************************************************************************/

    .error__statut {
        font-size: 100px;
        margin-bottom: -15px;
    }
    
    .error__failled {
        width: 300px;
    }
    
    .error__subheader {
        margin-bottom: 10px;
        font-size: 20px;
    }
    
    .error__link {
        font-size: 20px;
    }
}