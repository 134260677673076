/**********************************************************************************************/
/****************************************** prestations ***************************************/
/**********************************************************************************************/

.why {
    width: auto;
    text-align: center;
    margin-top: -2.625vw;
    margin-bottom: 4.375vw;
    font-size: 5vw;
    font-weight: 700;
    letter-spacing: normal;
    color: rgb(80, 80, 80);
}

.why>header {
    margin-bottom: 7.5vw;
    padding: 2vw;
}

.why>p{
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-top: .875vw;
    margin-left: 1.75vw;
    margin-right: 1.75vw;
    font-size: 4.5vw;
    font-weight: 500;
    line-height: 5.3vw;
    color: rgb(110, 110, 110);
}

.containerImg {
    display: flex;
    width: auto;
    height: 75vw;
    overflow: hidden;
}

.containerImg__img,
.containerImg__img2 {
    display: inline-block;
    width: 188px;
    height: auto;
    filter: brightness(50%);
}

.containerImg-content {
    position: absolute;
    margin-top: 22vw;
    width: 100%;
    height: auto;
    z-index: 3000;
}

.prestations-working {
    text-align: center;
    width: 81.2vw;
    margin-bottom: 8vw;
    padding-left: 15vw;
    padding-right: 15vw;
    font-size: 4.5vw;
    font-weight: 800;
    line-height: 5.5vw;
    letter-spacing: .175vw;
    color: white;
}

.prestations-contact {
    position: relative;
    text-align: center;
    top: -7vw;
    margin-left: 16vw;
    margin-right: 16vw;
    margin-bottom: 0;
    padding: 3vw 3vw 3.4vw 3vw;
    border-radius: 0.875vw;
    font-size: 4.5vw;
    letter-spacing: .0437vw;
    font-weight: 600;
    color: black;
    background-color: rgb(255, 191, 0);
    cursor: pointer;
    transition: color .4s, background-color .4s;
    box-shadow: rgba(0, 0, 0, 0.24) 0 .262vw .7vw;
}

.prestations-contact:hover {
    color: rgb(255, 255, 255);
    background-color: orange;
    box-shadow: rgba(0, 0, 0, 0.24) 0 .262vw .7vw;
    transition: color .4s, background-color .4s;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /****************************************** prestations ***************************************/
    /**********************************************************************************************/

    .why {
        margin-top: -1.555vw;
        margin-bottom: 2.592vw;
        font-size: 2.963vw;
    }
    
    .why>header {
        margin-bottom: 4.444vw;
        padding: 1.185vw;
    }
    
    .why>p{
        margin-top: .518vw;
        margin-left: 1.037vw;
        margin-right: 1.037vw;
        font-size: 2.37vw;
        line-height: 3.141vw;
    }

    .containerImg {
        height: 36vw;
    }
    
    .containerImg__img,
    .containerImg__img2 {
        width: 188px;
    }
    
    .containerImg-content {
        margin-top: 10vw;
    }
    
    .prestations-working {
        width: 100%;
        margin-bottom: 6vw;
        padding-left: 0;
        padding-right: 0;
        font-size: 2.666vw;
        line-height: 3.259vw;
        letter-spacing: .104vw;
    }
    
    .prestations-contact {
        top: -4.148vw;
        margin-left: 32vw;
        margin-right: 32vw;
        padding: 1.777vw 1.777vw 2.015vw 1.777vw;
        border-radius: 0.518vw;
        font-size: 2.37vw;
        letter-spacing: .0259vw;
        box-shadow: rgba(0, 0, 0, 0.24) 0 .155vw 4.148vw;
    }
    
    .prestations-contact:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0 .155vw 4.148vw;
    }
}