.modal-container-notif {
    transition-duration: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3100;
}

.modal-notif {
    min-width: 200px;
    position: absolute;
    background-color: rgb(93, 92, 92);
    padding: 7px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    right: 0;
    top: 40px;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.link-modal{
    cursor: pointer;
    flex: 1;
    display: flex;
    padding: 5px 8px;
    border-radius: 7px;
}

.link-modal:hover {
    background-color: rgb(52, 45, 45);
}

.profil-modal {
    display: flex;
  flex: 1;
  width: 100%;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start ;
}

.profil-info-modal {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: start;
}

.photo-modal {
    width: 40px;
    height: 40px;
    background-color: rgb(62, 54, 54);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profil-initial-modal {
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-top: 4px;
}

.full-name-modal {
    display: flex;
    gap: 0.3rem;
    font-weight: 500;
    font-size: 1rem;
    color: white;
}

.date-modal{
    color: white;
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}