/**********************************************************************************************/
/***************************************** realisations ***************************************/
/**********************************************************************************************/

.vdo-container {
    filter: grayscale(1) brightness(50%);
}

.galery-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 4.8vw;
    width: 60vw;
    height: auto;
    z-index: 2000;
}

.galery__logo-2br {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    top: 4vw;
    width: 38vw;
    height: auto;
    z-index: -1;
}

.galery-button {
    position: absolute;
    text-align: center;
    top: 11vw;
    width: 45vw;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 4.5vw;
    font-weight: 600;
    line-height: 5.5vw;
    padding: 2vw 2vw 2vw 2vw;
    border: .146vw solid rgb(255, 191, 0);
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    cursor: pointer;
    transition: background-color .7s
}

.galery-button:hover {
    background-color: rgb(255, 191, 0);
    color: black;
    transition: background-color .5s;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /***************************************** realisations ***************************************/
    /**********************************************************************************************/

    .galery-container {
        top: 12.78vw;
        width: 50vw;
    }

    .galery-button {
        top: 7vw;
        width: 32vw;
        font-size: 2.5vw;
        line-height: 3vw;
        border: .175vw solid rgb(255, 191, 0);
    }

    .galery__logo-2br {
        top: 0;
        width: 30vw;
    }
}