/**********************************************************************************************/
/********************************************* banner *****************************************/
/**********************************************************************************************/

.banner {
    position: relative;
    width: 100%;
    height: 188vw;
    padding-left: 6.5vw;
    background-color: rgb(177, 148, 63);
}

.banner__picture1 {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    object-fit: contain;
    width: auto;
    height: 100%;
    margin-bottom: -2vw;
}

.banner__picture2 {
    display: none;
}

.banner__picture--in {
    opacity: 7;
    transition: 2s;
}

.banner__picture--out {
    opacity: 0;
    transform: scale(1.08, 1.08);
    transition: all 2s ease;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* banner *****************************************/
    /**********************************************************************************************/
    
    .banner {
        padding-left: 2.963vw;
        height: 66vw;
    }

    .banner__picture1 {
        display: none;
    }
    
    .banner__picture2 {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        object-fit: contain;
        width: 100%;
        height: auto;
        margin-bottom: -1.185vw;
    }
}