.notif {
    width: 35px;
    height: 35px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(18, 105, 92);
    border-radius: 50%;
    cursor: pointer;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    border: none;
}

.bell {
    width: 20px;
    font-size: 1rem;
}

.bell {
    fill: white;
}

/* .notif:hover {
    background-color: rgb(56, 56, 56);
} */

.bell:hover {
    animation: bellRing 0.9s both;
}

/* bell ringing animation keyframes*/
@keyframes bellRing {

    0%,
    100% {
        transform-origin: top;
    }

    15% {
        transform: rotateZ(10deg);
    }

    30% {
        transform: rotateZ(-10deg);
    }

    45% {
        transform: rotateZ(5deg);
    }

    60% {
        transform: rotateZ(-5deg);
    }

    75% {
        transform: rotateZ(2deg);
    }
}

.bell:active {
    transform: scale(0.8);
}

.notif-count {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: red;
    padding: 5px;
    position: absolute;
    border-radius: 50%;
    font-size: 12px;
    top: -9px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    min-height: 22px;
    padding: 6px;
    z-index: 10;
}