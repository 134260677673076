/************************************************************************************************************/
/********************************************* media queries ************************************************/
/********************************** New rules if the screen is at more 767px ********************************/
@media screen and (min-width: 768px) {
    header, 
    section, 
    article {
        margin-left: auto;
        margin-right: auto;
    }

    .logout__btn {
		font-size: 28px;
	}
}