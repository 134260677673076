/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* footer *****************************************/
    /**********************************************************************************************/
    
    .footer > h1 {
        font-size: 3.5vw;
    }
    
    .footer__link {
        width: 34vw;
    }
    
    .footer__link__icon {
        font-size: 5vw;
    }
    
    .footer__logo-2br {
        margin-top: 4vw;
        margin-bottom: 4vw;
    }
    
    .footer > p {
        font-size: 1.8vw;
        line-height: 3vw;
    }
    
    .footer__copyright {
        font-size: 1.8vw;
    }    
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* footer *****************************************/
    /**********************************************************************************************/
    
    .footer > h1 {
        font-size: 2.8vw;
    }
    
    .footer__link {
        width: 30vw;
    }
    
    .footer__link__icon {
        font-size: 4vw;
    }
    
    .footer__logo-2br {
        margin-top: 4vw;
        margin-bottom: 4vw;
    }
    
    .footer > p {
        font-size: 1.6vw;
        line-height: 2.5vw;
    }
    
    .footer__copyright {
        font-size: 1.6vw;
    }    
}