@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:wght@100,200,300;400;500;600;700&display=swap');

body {
    margin: 0 auto;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    color: white;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}