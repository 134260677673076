/**********************************************************************************************/
/******************************************** agences *****************************************/
/**********************************************************************************************/

.containerOffice__bloc {
    width: auto;
    height: auto;
}

.containerOffice__bloc__office__pic {
    position: relative;
    width: 100%;
    height: auto;
    filter: brightness(30%);
}

.containerOffice__bloc__office__text {
    position: absolute;
    display: inline-block;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    padding-top: 3vw;
    color: white;
    font-size: 4vw;
    font-weight: 500;
    line-height: 1.5vw;
    z-index: 1000;
}

.containerOffice__bloc__office__text__content {
    margin-top: -2vw;
}

.containerOffice__bloc__office__text__arrow {
    cursor: pointer;
    z-index: 3000;
}

.containerOffice__bloc__office__text__arrow__left {
    float: left;
    margin-left: 5vw;
}

.containerOffice__bloc__office__text__arrow__right {
    float: right;
    margin-right: 5vw;
}

.containerOffice__bloc__office__text__container__dote {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 4vw;
    width: 7vw;
    bottom: 3.5vw;
    z-index: 2000;
}

.agences-dot {
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
    transition: background-color .5s;
    cursor: pointer;
}

.agences-dot:hover {
    background-color: rgb(255, 191, 0);
    transition: background-color .5s;
}

.containerOffice__bloc__office__text__content > p > a,
.agences-adress {
    color: rgb(255, 220, 113) !important; 
}

.agences-adress {
    margin-top: -1vw;
    line-height: 5vw;
    cursor: pointer;
}

.containerOffice__bloc__office__text__content > p > a:hover,
.agences-adress:hover {
    text-decoration: underline;
    color: rgb(255, 220, 113);
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/

    .containerOffice__bloc__office__text {
        padding-top: 1.777vw;
        font-size: 2.37vw;
        line-height: 0.888vw;
    }
    
    .containerOffice__bloc__office__text__content {
        height: 33vw;
        margin-top: 13vw;
    }
    
    .containerOffice__bloc__office__text__arrow__left {
        margin-left: 4vw;
    }
    
    .containerOffice__bloc__office__text__arrow__right {
        margin-right: 4vw;
    }
    
    .containerOffice__bloc__office__text__container__dote {
        right: 4vw;
        width: 4.148vw;
        bottom: 3vw;
    }
    
    .agences-dot {
        width: 1.3vw;
        height: 1.3vw;
    }

    .agences-adress {
        margin-top: -1.6vw;
    }
}