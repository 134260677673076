/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .containerContact__bloc__pub {
        top: 132vw;
        margin-left: 0;
        margin-right: 3vw;
        font-size: 3vw;
        line-height: 3.5vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .containerContact {
        height: 140.1vw;
    }

    .containerContact__text {
        width: 45.15vw;
        margin: 3.5vw 4.375vw 4.375vw 4.375vw;
        font-size: 1.4vw;
    }

    .containerContact__text__header {
        font-size: 2.45vw;
        line-height: 3.062vw;
    }

    .containerContact__text__form {
        margin: 5.25vw 0 4.375vw 5.25vw;
        padding-right: 4.375vw;
        top: 5.687vw;
        left: -2vw;
        width: 43.75vw;
    }

    .containerContact__bloc {
        right: 0.2vw;
    }

    .containerContact__bloc__handshake {
        width: 64.8vw;
        margin-right: -10vw;
        margin-top: 45.5vw;
    }

    .containerContact__bloc__pub {
        top: 104vw;
        margin-left: -5vw;
        margin-right: 2vw;
        font-size: 2.7vw;
        line-height: 3.5vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /******************************************** contact *****************************************/
    /**********************************************************************************************/

    .containerContact {
        height: 127.6vw;
        border: .073vw solid rgba(26, 131, 113, .4);
    }

    .containerContact__text {
        width: 37.668vw;
        margin: 2.92vw 3.65vw 3.65vw 3.65vw;
        font-size: 1.168vw;
    }

    .containerContact__text__header {
        font-size: 2.044vw;
        font-weight: 600;
        line-height: 2.555vw;
    }

    .containerContact__text__form {
        margin: 4.38vw 0 3.65vw 4.38vw;
        padding-left: 3.65vw;
        padding-right: 3.65vw;
        top: 4.745vw;
        width: 36.5vw;
    }

    .containerContact__bloc {
        right: 0.12vw;
    }

    .containerContact__bloc__handshake {
        width: 54.093vw;
        margin-right: -1vw;
        margin-top: 48.66vw;
    }

    .containerContact__bloc__pub {
        top: 98.84vw;
        margin-left: 3vw;
        margin-right: 1vw;
        font-weight: 800;
        font-size: 2.555vw;
        line-height: 2.92vw;
    }
}