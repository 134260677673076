/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/

    .house {
        width: 111vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/

    .house {
        width: 105.6vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/
    
    .house {
        width: 103vw;
    }
}