/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* Card *******************************************/
    /**********************************************************************************************/

    .carrousel__count {
        font-size: 2vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* Card *******************************************/
    /**********************************************************************************************/

    .carrousel__count {
        font-size: 1.8vw;
    }
}