/**********************************************************************************************/
/****************************************** galerieCard ***************************************/
/**********************************************************************************************/

/************************************************************************************************************/
/********************************************* media queries ************************************************/
/********************************** New rules if the screen is at more 767px ********************************/
@media screen and (min-width: 768px) {
    .galerie-card {
        left: 50%;
        transform: translate(-50% ,0);
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        width: 90.7vw;
        padding-top: 4vw;
        padding-bottom: 0.44vw;
        border-radius: 1.8vw;
        background-color: rgb(246, 246, 246);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 10vw;
    }

    .galerie-card__chantier {
        width: 24.9vw;
        height: 24.9vw;
        margin-bottom: 3.66vw;
        border-radius: 0.7vw;
    }
    
    .galerie-card__chantier__background {
        width: 100%;
        height: 24.9vw;
    }
    
    .galerie-card__chantier__picture {
        height: 24.9vw;
    }
    
    .galerie-card__chantier__title {
        margin-left: 1.5vw;
        margin-right: 1.5vw;
        width: 22vw;
        font-size: 1.7vw;
        margin-bottom : 1.5vw;
    }
}

/************************************************************************************************************/
/********************************************* media queries ************************************************/
/********************************** New rules if the screen is at more 991px ********************************/
@media screen and (min-width: 992px) {
    .galerie-card {
        margin-bottom: 10vw;
    }

    .galerie-card__chantier__title {
        font-size: 1.4vw;
    }
}