/********************************************************************************************/
/******************************************** card ******************************************/
/********************************************************************************************/

.card {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-bottom: 2vw;
    padding-left: 7vw;
    padding-right: 7vw;
    font-family: Open, sans-serif;
    background-color: rgb(245, 245, 246);
    color: rgb(18, 105, 92);
}

.header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card__title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
}

.card__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 30px;
    margin-top: 50px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card__container__info {
    font-weight: normal;
    color: black;
    font-family: Open, sans-serif;
}

.profil {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
}

.profil__border {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(34, 34, 34, .2);
}

.name {
    font-weight: normal;
    font-size: 22px !important;
}

.photo {
    width: 80px;
    height: 80px;
    background-color: rgb(18, 105, 92);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profil-content {
    display: flex;
    align-items: self-start;
    flex-direction: column;
    gap: 1rem;
}

.profil-initial {
    font-size: 2.2rem;
    color: white;
}

.phone {
    margin-top: 20px;
    color: initial;
    display: flex;
    gap: .5rem;
    font-size: 20px;
    font-family: Open, sans-serif;
    flex-wrap: wrap;
}

.email {
    color: initial;
    font-size: 20px;
}

.phone-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.icon {
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    height: 35px;
    width: 35px;
}

.call {
    color: #00b3ff !important;
}

.whathapp {
    color: #21b658 !important;
}

.phone-text {
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 20px;
    height: 35px;
    color: black;
    padding: 5px 8px;
    text-align: center;
}
.phone-text:hover {
   color: blue;
}

.message {
    color: initial;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.message-title {
    font-size: 20px;
    font-weight: bold;
}

.message-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: .5rem ;
    border: 1px solid rgb(62, 54, 54);
    padding: .3rem .5rem .1rem .5rem ;
    border-radius: 5px;
}
.content{
    text-align: center;
}
.date-container{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.date-message{
    font-size: 13px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
