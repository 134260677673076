/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* slogan *******************************************/
    /**********************************************************************************************/

    .slogan__filtre {
        height: 60vw;
        font-size: 4.375vw;
        line-height: 6.125vw;
    } 
    
    .slogan__filtre__arrow {
        margin-top: 0;
        font-size: 1.75vw;
    }

    .slogan__filtre__text {
        line-height: 5.25vw;
        margin-top: 8vw;
        margin-bottom: -4.375vw;
    } 

    .slogan__filtre__more {
        font-size: 2vw;
    }

    .slogan__filtre__container__dote {
        width: 5.25vw;
    }

    .dot {
        width: 1vw;
        height: 1vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* slogan *******************************************/
    /**********************************************************************************************/

    .slogan {
        left: -22vw;
    }

    .slogan__filtre {
        padding-left: 22vw;
        padding-right: 22vw;
        width: 85vw;
        height: 45vw;
        font-size: 4.375vw;
        line-height: 6.125vw;
    } 
    
    .slogan__filtre__arrow {
        font-size: 1.75vw;
    }

    .slogan__filtre__arrow__left {
        margin-left: -2vw;
    }

    .slogan__filtre__arrow__right {
        margin-right: -19vw;
    }

    .slogan__filtre__text {
        width: 52.5vw;
        line-height: 5.25vw;
        margin-bottom: -4.375vw;
    } 

    .slogan__filtre__more {
        padding: 1.575vw 2.187vw 1.575vw 2.187vw;
        font-size: 1.75vw;
    }

    .slogan__filtre__container__dote {
        right: 2.187vw;
        bottom: 1.75vw;
        width: 5.25vw;
    }

    .dot {
        width: .8vw;
        height: .8vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* slogan *******************************************/
    /**********************************************************************************************/

    .slogan__filtre {
        width: 58.4vw;
        height: 40vw;
        padding-left: 5vw;
        padding-right: 5vw;
        font-size: 3.65vw;
        line-height: 5.11vw;
    } 
    
    .slogan__filtre__arrow {
        font-size: 1.46vw;
    }

    .slogan__filtre__arrow__left {
        margin-left: -1.8vw;
    }

    .slogan__filtre__arrow__right {
        margin-right: -1.6vw;
    }

    .slogan__filtre__text {
        width: 43.8vw;
        line-height: 4.38vw;
        margin-bottom: -3.65vw;
    } 

    .slogan__filtre__more {
        border: .146vw;
        padding: 1.314vw 1.825vw 1.314vw 1.825vw;
        font-size: 1.46vw;
    }

    .slogan__filtre__container__dote {
        right: 1.825vw;
        bottom: 1.46vw;
        width: 4.38vw;
    }

    .dot {
        width: .7vw;
        height: .7vw;
    }
}