/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /****************************************** prestations ***************************************/
    /**********************************************************************************************/
    
    .why {
        margin-top: -2.625vw;
        margin-bottom: 4.375vw;
        font-size: 2.6vw;
    }

    .why>header {
        margin-bottom: 3.5vw;
    }

    .why>p{
        width: 35vw;
        margin-top: .875vw;
        margin-left: 1.75vw;
        margin-right: 1.75vw;
        font-size: 2vw;
        line-height: 2.5vw;
    }

    .containerImg-content {
        margin-top: 10vw;
    }

    .prestations-working {
        font-size: 2.3vw;
    }

    .prestations-contact {
        margin-left: 30vw;
        margin-right: 30vw;
        padding: 1.4vw 2.2vw 1.4vw 2.2vw;
        font-size: 2vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /****************************************** prestations ***************************************/
    /**********************************************************************************************/
    
    .why {
        margin-top: -4.625vw;
        margin-bottom: 4.375vw;
        font-size: 2.3vw;
    }

    .why>header {
        margin-bottom: 1.5vw;
    }

    .why>p{
        width: 27.562vw;
        margin-top: .875vw;
        margin-left: 1.75vw;
        margin-right: 1.75vw;
        font-size: 1.575vw;
        line-height: 2.187vw;
    }
    
    .containerImg {
        height: 24.762vw;
    }

    .containerImg__img,
    .containerImg__img2 {
        width: 188px;
    }

    .containerImg-content {
        margin-top: 5.5vw;
    }

    .prestations-working {
        margin-bottom: 5vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /****************************************** prestations ***************************************/
    /**********************************************************************************************/
    
    .why {
        margin-top: -2.19vw;
        margin-bottom: 3.65vw;
        font-size: 2.2vw;
        font-weight: 700;
    }

    .why>header {
        margin-bottom: 1.5vw;
    }

    .why>p{
        width: 23vw;
        margin-top: .73vw;
        margin-left: 1.8vw;
        margin-right: 1.8vw;
        font-size: 1.3vw;
        font-weight: 500;
        line-height: 1.9vw;
    }
    
    .containerImg {
        height: 20.659vw;
    }

    .containerImg-content {
        margin-top: 3.5vw;
    }
}